import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { AppRouting } from "./app.routing";
import ComponentLoader from "./components/component-loader/component-loader";
import { LanguagesEnum } from "./core/enums/languages.enum";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import "firebase/compat/remote-config";
import firebase from "firebase/compat/app";

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBattyhQ-qnGhh0acda2wTN4Ww9HNay5QQ",
    authDomain: "drahim-ios-app.firebaseapp.com",
    projectId: "drahim-ios-app",
    storageBucket: "drahim-ios-app.appspot.com",
    messagingSenderId: "1037620779716",
    appId: "1:1037620779716:web:960cc231cc412263858791",
    measurementId: "G-JYS0QFDTH5",
  };
  firebase.initializeApp(firebaseConfig);
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.defaultConfig = require("../src/core/stores/remote_config_defaults.json");
  remoteConfig.fetchAndActivate();

  const { i18n } = useTranslation();

  return (
    <Suspense fallback={<ComponentLoader />}>
      <main
        className="bg-white min-h-screen w-screen font"
        dir={i18n.language === LanguagesEnum.AR ? "rtl" : "ltr"}
      >
        <Header />
        <div className="bg-[#FAFAFA] shadow-body rounded-b-[60px] xl:pb-20 pb-10">
          <AppRouting />
        </div>
        <Footer />
      </main>
    </Suspense>
  );
}

export default App;
