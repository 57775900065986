import i18n from "../../i18n";
import { makeRequest, baseURL, cmsURL, emailURL } from "../config/http";
import { HttpMethodsEnum } from "../enums/http-methods.enum";
import { LanguagesEnum } from "../enums/languages.enum";
import { LocalizedString } from "../types/investment-content.types";

export const getSeedService = () => {
  return makeRequest({
    url: `${baseURL}/investment/portfolio`,
    method: HttpMethodsEnum.GET,
  });
};

export const validateEduEmailService = (data: { email: string }) => {
  return makeRequest({
    url: `${emailURL}/promotion/edu/request`,
    method: HttpMethodsEnum.POST,
    data,
  });
};

export const sendMaziahEmailVerification = (data: { email: string }) => {
  return makeRequest({
    url: `${emailURL}/promotion/maziah/send-verification-email`,
    method: HttpMethodsEnum.POST,
    data,
  });
};

export const sendKKUEmailVerification = (data: { email: string }) => {
  return makeRequest({
    url: `${emailURL}/promotion/kku/send-verification-email`,
    method: HttpMethodsEnum.POST,
    data,
  });
};

export const activateKKUEmail = (data: { email: string; otp: string }) => {
  return makeRequest({
    url: `${emailURL}/promotion/kku/verify-email`,
    method: HttpMethodsEnum.POST,
    data,
  });
};

export const activateMaziahEmail = (data: { email: string; otp: string }) => {
  return makeRequest({
    url: `${emailURL}/promotion/maziah/verify-email`,
    method: HttpMethodsEnum.POST,
    data,
  });
};

export const validateEduEmailOtpService = (params: { otp: string }) => {
  return makeRequest({
    url: `${emailURL}/promotion/edu/submit`,
    method: HttpMethodsEnum.POST,
    params,
  });
};

export const getBolgsService = () => {
  return makeRequest({
    url: `${cmsURL}/articles/?sortBy=updatedAt&sortDir=desc&pageSize=9&pageNo=0`,
    method: HttpMethodsEnum.GET,
  });
};

export const getBolgByIdService = (blogId: string) => {
  return makeRequest({
    url: `${cmsURL}/articles/${blogId}`,
    method: HttpMethodsEnum.GET,
  });
};

export function localizedString(localizedString: LocalizedString): string {
  return i18n.language === LanguagesEnum.AR
    ? localizedString.ar
    : localizedString.en;
}
