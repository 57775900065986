import drahimLogo from "../../assets/images/drahim-logo.svg";

import sama from "../../assets/images/sama-gray.svg";
import cma from "../../assets/images/cma-gray.svg";

import linkedIn from "../../assets/icons/linkedIn.svg";
import twitter from "../../assets/icons/twitter.svg";
import instagram from "../../assets/icons/instagram.svg";
import googlePlay from "../../assets/icons/google-play.png";
import appStore from "../../assets/icons/app-store.svg";

import { Link } from "react-router-dom";
import { appRoutesObj } from "../../app.paths";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs xl:my-28 my-16 flex xl:items-center justify-between xl:flex-row flex-col gap-20">
        <img
          src={drahimLogo}
          alt={t("components.Footer.title")!}
          className="xl:h-16 h-12 w-fit"
        />
        <div className="flex items-center xl:gap-8 gap-6">
          <a
            href="https://drahim.page.link/website"
            className="shadow-btn bg-white rounded-[20px] xl:w-[178px] w-[154px] p-3 flex items-center justify-center"
          >
            <img
              src={googlePlay}
              alt={t("app.shared.download")!}
              className="h-10 w-auto object-contain"
            />
          </a>
          <a
            href="https://drahim.page.link/website"
            className="shadow-btn bg-white rounded-[20px] xl:w-[178px] w-[154px] p-3 flex items-center justify-center"
          >
            <img
              src={appStore}
              alt={t("app.shared.download")!}
              className="h-10 w-auto object-contain"
            />
          </a>
        </div>
      </div>
      <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs h-px bg-[#F6F6F6]"></div>
      <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs xl:my-28 my-16">
        <div className="grid xl:grid-cols-7 grid-cols-2 items-start justify-between gap-10">
          <div className="col-span-2 xl:order-first order-last">
            <p className="text-[#B3BAC5] text-xs mb-6">
              {t("components.Footer.auth")}
            </p>
            <div className="gap-6 flex xl:flex-col flex-row">
              <img
                src={sama}
                alt={t("components.Footer.sama")!}
                className="xl:w-40 w-36 h-auto"
              />
              <img
                src={cma}
                alt={t("components.Footer.cma")!}
                className="xl:w-40 w-36 h-auto"
              />
            </div>
          </div>
          <div>
            <p className="text-[#B3BAC5] text-xs mb-6">
              {t("components.Footer.about")}
            </p>
            <div className="flex flex-col gap-4">
              <Link
                to={appRoutesObj.getInvestUrl()}
                className="text-black opacity-60 text-sm"
              >
                {t("components.Footer.invest")}
              </Link>
              {/* <Link
                to={appRoutesObj.getBlogsUrl()}
                className="text-black opacity-60 text-sm"
              >
                {t("components.Footer.blogs")}
              </Link> */}
              {/* <Link
                to={appRoutesObj.getEduUrl()}
                className="text-black opacity-60 text-sm"
              >
                {t("components.Footer.edu")}
              </Link> */}
            </div>
          </div>
          <div>
            <p className="text-[#B3BAC5] text-xs mb-6">
              {t("components.Footer.termsTitle")}
            </p>
            <div className="flex flex-col gap-4">
              <Link
                to={appRoutesObj.getPrivacyPolicyUrl()}
                className="text-black opacity-60 text-sm"
              >
                {t("components.Footer.privacy")}
              </Link>
              <a
                href={"/fatca-crs-ar.pdf"}
                target="_blank"
                className="text-black opacity-60 text-sm"
              >
                {"قانون (فاتكا) و قانون المعيار المشترك"}
              </a>
              <a
                href={"/fatca-crs-en.pdf"}
                target="_blank"
                className="text-black opacity-60 text-sm"
              >
                {"FATCA and CRS Regulations"}
              </a>
            </div>
          </div>
          <div>
            <p className="text-[#B3BAC5] text-xs mb-6">
              {t("components.Footer.contact")}
            </p>
            <div className="flex flex-col gap-4">
              <a
                href="mailto:contact@drahim.sa"
                target="_blank"
                rel="noreferrer"
                className="text-black opacity-60 text-sm"
              >
                {t("components.Footer.email")}
              </a>
            </div>
          </div>
          <div className="col-span-2 flex justify-start xl:justify-end gap-10">
            <a href="https://www.instagram.com/getdrahim">
              <img src={instagram} alt="follow us on  instagram" />
            </a>
            <a href="https://www.linkedin.com/company/drahim/">
              <img src={linkedIn} alt="follow us on linkedIn" />
            </a>
            <a href="https://twitter.com/getDrahim">
              <img src={twitter} alt="follow us on  twitter" />
            </a>
          </div>
        </div>
      </div>
      <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs my-10">
        <p className="text-[#C1C7D0] text-xs text-center">
          {t("components.Footer.copyRight")}
        </p>
      </div>
    </>
  );
}
