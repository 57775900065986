import { useFormik } from "formik";
import { useState } from "react";
import { validationSchema } from "./kku-email-form-container.validation";
import { useTranslation } from "react-i18next";
import {
  DirectionEnum,
  InputField,
} from "../../components/input-field/input-field";
import ComponentLoader from "../../components/component-loader/component-loader";
import { sendKKUEmailVerification } from "../../core/services/shared.services";
import { LanguagesEnum } from "../../core/enums/languages.enum";
import { toast } from "react-toastify";

export default function KKUEmailFormContainer() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const initalFormData = {
    email: "",
  };

  const handleSubmit = async (data: { email: string }) => {
    setIsLoading(true);
    try {
      await sendKKUEmailVerification(data);
      toast.success(
        "تم ارسال رابط التحقق على البريد الإلكتروني، الرجاء التحقق علماً بأن صلاحية الرابط هي 15 دقيقة.",
      );
    } catch (error) {
      const anyError: any = error;
      const localeObject = anyError?.response?.data;
      if ("arabic_message" in localeObject) {
        toast.error(localeObject["arabic_message"]);
      } else {
        toast.error("حدث خطأ، الرجاء المحاولة مرة أخرى.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initalFormData,
    onSubmit: (values: { email: string }) => {
      handleSubmit({
        ...values,
      });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return (
    <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs my-20">
      <form className="text-black" onSubmit={formik.handleSubmit}>
        <div className="flex items-center justify-center w-full gap-4">
          <div className="w-full">
            <InputField
              aria-label={t("container.KKUEmailFormContainer.title")!}
              placeholder={t("container.KKUEmailFormContainer.placeholder")!}
              name="email"
              type="email"
              value={formik.values.email ?? ""}
              onChange={formik.handleChange}
              dir={
                i18n.language === LanguagesEnum.AR
                  ? DirectionEnum.RTL
                  : DirectionEnum.LTR
              }
              className="font-light !text-sm"
            />
          </div>

          <div className="mt-4 flex justify-around gap-2">
            <button
              type="submit"
              disabled={isLoading}
              className="rounded-[20px] bg-[#FBD61A] flex items-center justify-center gap-3 px-6 py-3 text-black text-sm"
            >
              <div className="flex items-center cursor-pointer">
                {isLoading && (
                  <div className="w-6">
                    <ComponentLoader />
                  </div>
                )}
                {t("container.KKUEmailFormContainer.cta")}
              </div>
            </button>
          </div>
        </div>
        {formik.touched.email && formik.errors.email && (
          <p className="text-danger font-light text-sm -mt-5 mb-5">
            {formik.errors.email}
          </p>
        )}
      </form>
    </div>
  );
}
