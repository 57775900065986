import { useTranslation } from "react-i18next";
import kkuHero from "../../assets/images/kku-hero.png";

export default function KKUHeroContainer() {
  const { t } = useTranslation();

  return (
    <div className="w-full flex xl:pt-64 pt-28 relative overflow-hidden items-center justify-center p-2 pb-0">
      <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs flex xl:flex-row flex-col-reverse items-center justify-center xl:gap-20 gap-10">
        <div className="xl:w-2/3">
          <h1 className="font-bold xl:text-5xl text-black xl:text-start text-4xl text-center xl:mb-14 mb-8">
            {t("container.KKU.title.1")}
            <p className="mt-4 !font-normal">{t("container.KKU.title.2")}</p>
          </h1>
          <p className="text-[#8993A4] xl:text-start text-center xl:text-xl text-sm font-light">
            {t("container.KKU.body")}
          </p>
        </div>
        <img src={kkuHero} className="xl:w-96" />
      </div>
    </div>
  );
}
