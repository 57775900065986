import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { LanguagesEnum } from "../../core/enums/languages.enum";
import logo from "../../assets/images/drahim-logo.svg";
import { getHeaderLinks } from "./header.data";

interface HeaderDesktopProps {
  handleLogoClick: () => void;
  handleLanguageChange: () => void;
}

export default function HeaderDesktop({
  handleLogoClick,
}: // handleLanguageChange,
HeaderDesktopProps) {
  const { i18n, t } = useTranslation();
  const location = useLocation();

  return (
    <div className="mx-auto flex justify-center w-full sm:max-w-6xl relative">
      <div className="flex items-center justify-center">
        <div
          className={`h-full flex items-center justify-center ${
            i18n.language === LanguagesEnum.EN ? "mr-12" : "ml-12"
          }`}
        >
          <img
            src={logo}
            alt={t("app.shared.drahim")!}
            className="h-9 w-auto cursor-pointer"
            onClick={handleLogoClick}
          />
        </div>

        {getHeaderLinks(location.pathname).map((link, index) =>
          index === 2 ? (
            <a
              key={`link-${index}`}
              href={link.to}
              className="flex group flex-col"
            >
              <div
                className={`mx-10 group-hover:text-primary text-[#8993A4] font-semibold text-sm transition-all duration-300 ${
                  link.isActive ? "text-primary" : ""
                }`}
              >
                {link.name}
              </div>
            </a>
          ) : (
            <Link
              key={`link-${index}`}
              to={link.to}
              className="flex group flex-col"
            >
              <div
                className={`mx-10 group-hover:text-primary text-[#8993A4] font-semibold text-sm transition-all duration-300 ${
                  link.isActive ? "text-primary" : ""
                }`}
              >
                {link.name}
              </div>
            </Link>
          ),
        )}
        <button
          onClick={() =>
            window.open(
              "https://apps.apple.com/sa/app/drahim-%D8%AF%D8%B1%D8%A7%D9%87%D9%85/id1591612912?mt=8",
              "_blank",
            )
          }
          className="rounded-3xl bg-primary shadow-md shadow-primary flex items-center justify-center gap-3 p-3 text-white text-sm"
        >
          {t("app.shared.download")}
        </button>
      </div>
      {/* <button
        className="flex absolute left-0 my-auto inset-y-0 w-9 h-9 items-center justify-center border border-[#EBECF0] text-[#8993A4] rounded-[34px] p-2.5 text-xs font-semibold"
        onClick={handleLanguageChange}
      >
        EN
      </button> */}
    </div>
  );
}
