import { useEffect, useRef, useState } from "react";
import DrahimPlusContainer from "../../containers/drahim-plus-container/drahim-plus-container";
import KKUActivationHeroContainer from "./kku-activate-hero-container";
import { useParams, useSearchParams } from "react-router-dom";
import { activateKKUEmail } from "../../core/services/shared.services";

export default function KKUVerificationPage() {
  const [isActivated, setIsActivated] = useState<boolean | null>(null);
  const [searchParams, _] = useSearchParams();
  const initialized = useRef(false);

  const params = useParams();
  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    let token = searchParams.get("token");
    if (token == null) {
      setIsActivated(false);
      return;
    }
    let object = decodeBase64ToObject(token);
    activateKKUEmail(object)
      .then((data) => {
        setIsActivated(true);
      })
      .catch((error) => {
        setIsActivated(false);
      });
  }, []);

  function decodeBase64ToObject(base64String: string): any {
    const decodedString = atob(base64String);
    try {
      const obj = JSON.parse(decodedString);
      return obj;
    } catch (error) {
      console.error("Error parsing JSON from decoded string:", error);
      return null;
    }
  }

  return (
    <div className="bg-edu bg-cover bg-no-repeat bg-top">
      <KKUActivationHeroContainer isSuccess={isActivated} />
      <DrahimPlusContainer />
    </div>
  );
}
