import { useEffect, useState } from "react";
import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import { HiX } from "@react-icons/all-files/hi/HiX";
import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/images/drahim-logo.svg";
import { getHeaderLinks } from "./header.data";
import { useTranslation } from "react-i18next";

interface HeaderMobileProps {
  handleLogoClick: () => void;
  handleLanguageChange: () => void;
}

export default function HeaderMobile({
  handleLogoClick,
}: // handleLanguageChange,
HeaderMobileProps) {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isContentOptionsShown, setIsContentOptionsShown] =
    useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isCollapsed) {
      // to disable  scrolling on the page
      window.document.body.style.position = "fixed";
      window.document.body.style.width = "100%";
    } else {
      window.document.body.style.position = "unset";
    }
  }, [isCollapsed]);

  return (
    <div className="flex w-screen justify-between">
      <div
        className={`${
          isCollapsed ? "h-0 py-0" : "py-4 h-[260px]"
        } bg-white absolute top-[50px] z-[9999] p-5 shadow-xl w-60 rounded-[30px] backdrop-blur-xl left-0 transition-all duration-150 overflow-hidden`}
      >
        <div className="flex w-full flex-col gap-6 justify-start">
          {getHeaderLinks(location.pathname).map((link, index) => (
            <div className="text-sm text-[#8993A4] w-fit" key={`nav-${index}`}>
              <Link onClick={() => setIsCollapsed(true)} to={link.to}>
                <div className={`${link.isActive ? "text-primary" : ""}`}>
                  {link.name}
                </div>
              </Link>
            </div>
          ))}
          <div className="flex items-center justify-between gap-4">
            {/* <button
              className="flex w-9 h-9 items-center justify-center border border-[#EBECF0] text-[#8993A4] rounded-[34px] p-2.5 text-xs font-semibold"
              onClick={handleLanguageChange}
            >
              EN
            </button> */}
            <button
              onClick={() =>
                window.open(
                  "https://apps.apple.com/sa/app/drahim-%D8%AF%D8%B1%D8%A7%D9%87%D9%85/id1591612912?mt=8",
                  "_blank",
                )
              }
              className="rounded-3xl bg-primary shadow-md shadow-primary flex items-center justify-center gap-3 p-3 text-white text-sm"
            >
              {t("app.shared.download")}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-2 z-50">
        <img
          src={logo}
          alt={t("app.shared.drahim")!}
          className="h-10 w-auto"
          onClick={handleLogoClick}
        />
      </div>

      <div className="z-50">
        <button
          title="menu"
          type="button"
          className="mt-4 text-[#8993A4]"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
            setIsContentOptionsShown(!isContentOptionsShown);
          }}
        >
          {!isCollapsed ? <HiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>
    </div>
  );
}
