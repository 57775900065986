import DrahimPlusContainer from "../../containers/drahim-plus-container/drahim-plus-container";
import KKUEmailFormContainer from "./kku-email-form-container";
import KKUHeroContainer from "./kku-hero-container";

export default function MaziahPage() {
  return (
    <div className="bg-edu bg-cover bg-no-repeat bg-top">
      <KKUHeroContainer />
      <KKUEmailFormContainer />
      <DrahimPlusContainer />
    </div>
  );
}
