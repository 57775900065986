import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import useIsMobileScreen from "../../core/hooks/use-is-mobile-screen.hooks";
import { appRoutesObj } from "../../app.paths";
import { LanguagesEnum } from "../../core/enums/languages.enum";
import HeaderMobile from "./header.mobile";
import HeaderDesktop from "./header.desktop";
import { useEffect } from "react";

export default function Header() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useIsMobileScreen();

  const handleLanguageChange = () => {
    // if (i18n.language === LanguagesEnum.AR) {
    //   i18n.changeLanguage(LanguagesEnum.EN);
    //   localStorage.setItem("language", LanguagesEnum.EN);
    // } else {
    i18n.changeLanguage(LanguagesEnum.AR);
    localStorage.setItem("language", LanguagesEnum.AR);
    // }
  };

  const handleLogoClick = () => {
    navigate(appRoutesObj.getBaseUrl());
  };

  useEffect(() => {
    if (location.hash.includes("#features")) {
      if (location.pathname !== appRoutesObj.getBaseUrl()) {
        navigate(appRoutesObj.getBaseUrl());
      }

      window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
    }
  }, [location.hash, location.pathname, navigate]);

  return (
    <nav className="inset-x-0 absolute xl:max-w-6xl sm:max-w-4xl w-full max-w-xs mx-auto top-0 right-0 left-0 z-40 flex h-16 xl:h-14 xl:px-3 rounded-xl mt-2 gap-16">
      {isMobile ? (
        <HeaderMobile
          handleLanguageChange={handleLanguageChange}
          handleLogoClick={handleLogoClick}
        />
      ) : (
        <HeaderDesktop
          handleLanguageChange={handleLanguageChange}
          handleLogoClick={handleLogoClick}
        />
      )}
    </nav>
  );
}
