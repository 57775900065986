import { useTranslation } from "react-i18next";
import kkuHero from "../../assets/images/kku-hero.png";
import { IoIosCheckmarkCircle } from "@react-icons/all-files/io/IoIosCheckmarkCircle";
import { IoIosCloseCircle } from "@react-icons/all-files/io/IoIosCloseCircle";

interface KKUHeroContainerProps {
  isSuccess: boolean | null;
}

export default function KKUHeroContainer(props: KKUHeroContainerProps) {
  const { t } = useTranslation();

  const getTitle = () => {
    if (props.isSuccess == null) {
      return "";
    }
    if (props.isSuccess) {
      return t("container.kku.activate.title.success");
    } else {
      return t("container.kku.activate.title.failed");
    }
  };

  const getSubtitle = () => {
    if (props.isSuccess == null) {
      return "";
    }
    if (props.isSuccess) {
      return t("container.kku.activate.subtitle.success");
    } else {
      return t("container.kku.activate.subtitle.failed");
    }
  };

  return (
    <div className="w-full flex xl:pt-64 pt-28 relative overflow-hidden items-center justify-center p-2 pb-0">
      <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs flex xl:flex-row flex-col-reverse items-center justify-center xl:gap-20 gap-10">
        <div className="xl:w-2/3">
          {props.isSuccess != null && props.isSuccess ? (
            <IoIosCheckmarkCircle className="text-teal-700 text-7xl  xl:mb-8" />
          ) : (
            <p></p>
          )}
          {props.isSuccess != null && props.isSuccess == false ? (
            <IoIosCloseCircle className="text-rose-700 text-7xl  xl:mb-8" />
          ) : (
            <p></p>
          )}
          <h1 className="font-bold xl:text-5xl text-black xl:text-start text-4xl text-center xl:mb-2 mb-8">
            {getTitle()}
          </h1>
          <p className="text-[#8993A4] xl:text-start text-center xl:text-xl text-sm font-light">
            {getSubtitle()}
          </p>
        </div>
        <img src={kkuHero} className="xl:w-96" />
      </div>
    </div>
  );
}
