import { t } from "i18next";
import * as Yup from "yup";

export const validationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required(t("app.shared.form.errors.required")!)
      .email(t("app.shared.form.errors.email")!),
  });
};
