import plus from "../../assets/images/plus.png";
import drahimPlus from "../../assets/images/drahim-plus.png";
import features from "../../assets/images/plus-features.png";
import { useTranslation } from "react-i18next";
import { LanguagesEnum } from "../../core/enums/languages.enum";

export default function DrahimPlusContainer() {
  const { t, i18n } = useTranslation();

  return (
    <div className="xl:max-w-6xl sm:max-w-2xl mx-auto w-full max-w-xs xl:my-28 my-16 bg-[#F4F5F7] rounded-[40px] xl:h-72 hidden xl:block relative">
      <div className="flex">
        <img
          src={plus}
          alt={t("containers.DrahimPlusContainer.plus")!}
          className="w-96 -mt-8"
        />
        <div className="mx-10 mt-4">
          <img
            src={drahimPlus}
            alt={t("containers.DrahimPlusContainer.plus")!}
            className="h-20 w-auto"
          />
          <p className="text-[#B3BAC5] font-light text-3xl">
            {t("containers.DrahimPlusContainer.subscrip")}
          </p>
        </div>
      </div>
      <img
        src={features}
        alt={t("containers.DrahimPlusContainer.subscrip")!}
        className={`absolute h-44 -bottom-20 ${
          i18n.language === LanguagesEnum.AR ? "right-32" : "left-32 right-auto"
        }`}
      />
    </div>
  );
}
