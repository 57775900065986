import DrahimPlusContainer from "../../containers/drahim-plus-container/drahim-plus-container";
import MaziahEmailFormContainer from "./maziah-email-form-container";
import MaziahHeroContainer from "./maziah-hero-container";

export default function MaziahPage() {
  return (
    <div className="bg-edu bg-cover bg-no-repeat bg-top">
      <MaziahHeroContainer />
      <MaziahEmailFormContainer />
      <DrahimPlusContainer />
    </div>
  );
}
